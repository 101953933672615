<template>
    <div class="footer">
        <div class="container desktopFlex">
            <ul class="terms">
                <img src="@/assets/logo_white.svg" alt="">
                <li><a href="https://twitter.com/WallexLab" target="_blank">
                    <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-271 296.6 256.4 208.4" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M-14.6,321.2c-9.4,4.2-19.6,7-30.2,8.3c10.9-6.5,19.2-16.8,23.1-29.1c-10.2,6-21.4,10.4-33.4,12.8 c-9.6-10.2-23.3-16.6-38.4-16.6c-29,0-52.6,23.6-52.6,52.6c0,4.1,0.4,8.1,1.4,12c-43.7-2.2-82.5-23.1-108.4-55 c-4.5,7.8-7.1,16.8-7.1,26.5c0,18.2,9.3,34.3,23.4,43.8c-8.6-0.3-16.7-2.7-23.8-6.6v0.6c0,25.5,18.1,46.8,42.2,51.6 c-4.4,1.2-9.1,1.9-13.9,1.9c-3.4,0-6.7-0.3-9.9-0.9c6.7,20.9,26.1,36.1,49.1,36.5c-18,14.1-40.7,22.5-65.3,22.5 c-4.2,0-8.4-0.2-12.6-0.7c23.3,14.9,50.9,23.6,80.6,23.6c96.8,0,149.7-80.2,149.7-149.7c0-2.3,0-4.6-0.1-6.8 C-30.5,341-21.6,331.8-14.6,321.2"></path> </g></svg>                    
                    Twitter
                    </a>
                </li>
                <li><a href="https://www.facebook.com/profile.php?id=100086423545168" target="_blank">
                    <svg height="20px" width="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_428_1625)">
                        <path d="M18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18C9.05273 18 9.10547 18 9.1582 17.9965V10.9934H7.22461V8.73984H9.1582V7.08047C9.1582 5.15742 10.3324 4.10977 12.048 4.10977C12.8707 4.10977 13.5773 4.16953 13.7812 4.19766V6.20859H12.6C11.6684 6.20859 11.4855 6.65156 11.4855 7.30195V8.73633H13.718L13.4262 10.9898H11.4855V17.652C15.2473 16.5727 18 13.1098 18 9Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_428_1625">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        Facebook
                    </a>
                </li>
                <li><a href="https://www.linkedin.com/company/wallexlab/" target="_blank">
                    <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M41.4,508.1H-8.5V348.4h49.9 V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7 C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6 c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z "></path> </g></svg>
                    Linkedin
                    </a>
                </li>
                <li><a href="https://www.instagram.com/wallexlab/" target="_blank">
                    <svg width="20px" height="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_428_1635)">
                        <path d="M10.7227 9C10.7227 9.95142 9.95142 10.7227 9 10.7227C8.04858 10.7227 7.27734 9.95142 7.27734 9C7.27734 8.04858 8.04858 7.27734 9 7.27734C9.95142 7.27734 10.7227 8.04858 10.7227 9Z" fill="white"/>
                        <path d="M13.0283 5.95204C12.9455 5.72765 12.8133 5.52454 12.6417 5.35782C12.475 5.18616 12.272 5.05405 12.0475 4.97124C11.8654 4.90051 11.5918 4.81633 11.0879 4.7934C10.5429 4.76854 10.3795 4.76318 8.99957 4.76318C7.61955 4.76318 7.45613 4.7684 6.91121 4.79326C6.40735 4.81633 6.13365 4.90051 5.95169 4.97124C5.72716 5.05405 5.52405 5.18616 5.35747 5.35782C5.18581 5.52454 5.0537 5.72751 4.97075 5.95204C4.90002 6.13414 4.81584 6.40784 4.79291 6.9117C4.76805 7.45662 4.7627 7.62004 4.7627 9.00006C4.7627 10.3799 4.76805 10.5434 4.79291 11.0884C4.81584 11.5923 4.90002 11.8658 4.97075 12.0479C5.0537 12.2725 5.18567 12.4754 5.35733 12.6422C5.52405 12.8138 5.72702 12.9459 5.95155 13.0287C6.13365 13.0996 6.40735 13.1838 6.91121 13.2067C7.45613 13.2316 7.61942 13.2368 8.99944 13.2368C10.3796 13.2368 10.543 13.2316 11.0878 13.2067C11.5917 13.1838 11.8654 13.0996 12.0475 13.0287C12.4982 12.8549 12.8544 12.4987 13.0283 12.0479C13.099 11.8658 13.1832 11.5923 13.2062 11.0884C13.2311 10.5434 13.2363 10.3799 13.2363 9.00006C13.2363 7.62004 13.2311 7.45662 13.2062 6.9117C13.1833 6.40784 13.0991 6.13414 13.0283 5.95204ZM8.99957 11.6537C7.53386 11.6537 6.34569 10.4656 6.34569 8.99992C6.34569 7.53421 7.53386 6.34618 8.99957 6.34618C10.4651 6.34618 11.6533 7.53421 11.6533 8.99992C11.6533 10.4656 10.4651 11.6537 8.99957 11.6537ZM11.7582 6.86144C11.4157 6.86144 11.1381 6.58376 11.1381 6.24126C11.1381 5.89876 11.4157 5.62108 11.7582 5.62108C12.1007 5.62108 12.3784 5.89876 12.3784 6.24126C12.3783 6.58376 12.1007 6.86144 11.7582 6.86144Z" fill="white"/>
                        <path d="M9 0C4.0302 0 0 4.0302 0 9C0 13.9698 4.0302 18 9 18C13.9698 18 18 13.9698 18 9C18 4.0302 13.9698 0 9 0ZM14.1368 11.1307C14.1118 11.6808 14.0243 12.0564 13.8966 12.3852C13.6281 13.0794 13.0794 13.6281 12.3852 13.8966C12.0565 14.0243 11.6808 14.1117 11.1308 14.1368C10.5797 14.1619 10.4036 14.168 9.00014 14.168C7.5965 14.168 7.42058 14.1619 6.86934 14.1368C6.31934 14.1117 5.9436 14.0243 5.61497 13.8966C5.27 13.7668 4.95772 13.5634 4.69954 13.3005C4.43669 13.0424 4.23331 12.73 4.10353 12.3852C3.97581 12.0565 3.88834 11.6808 3.86334 11.1308C3.83794 10.5796 3.83203 10.4035 3.83203 9C3.83203 7.5965 3.83794 7.42044 3.8632 6.86934C3.8882 6.3192 3.97554 5.9436 4.10326 5.61484C4.23303 5.27 4.43655 4.95758 4.69954 4.69954C4.95758 4.43655 5.27 4.23317 5.61484 4.10339C5.9436 3.97568 6.3192 3.88834 6.86934 3.8632C7.42044 3.83807 7.5965 3.83203 9 3.83203C10.4035 3.83203 10.5796 3.83807 11.1307 3.86334C11.6808 3.88834 12.0564 3.97568 12.3852 4.10326C12.73 4.23303 13.0424 4.43655 13.3006 4.69954C13.5634 4.95772 13.767 5.27 13.8966 5.61484C14.0245 5.9436 14.1118 6.3192 14.1369 6.86934C14.1621 7.42044 14.168 7.5965 14.168 9C14.168 10.4035 14.1621 10.5796 14.1368 11.1307Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_428_1635">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>                        
                        Instagram
                    </a>
                </li>
                <li><a href="" target="_blank">
                    <svg width="20px" height="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="white"/>
                        <path d="M13.727 5.59337L14.625 4.7353V4.54712H11.5149L9.29864 10.0627L6.77761 4.54712H3.51716V4.7353L4.56545 5.99735C4.6155 6.04359 4.65411 6.10083 4.67825 6.16454C4.70239 6.22826 4.71139 6.29671 4.70455 6.36451V11.3247C4.7202 11.413 4.71463 11.5038 4.68832 11.5895C4.662 11.6752 4.61568 11.7534 4.55318 11.8177L3.375 13.2485V13.4346H6.72443V13.2464L5.54318 11.8146C5.48004 11.7505 5.43252 11.6726 5.40432 11.5871C5.37612 11.5016 5.36799 11.4108 5.38057 11.3217V7.03439L8.31989 13.4367H8.6625L11.1897 7.03439V12.1347C11.1897 12.2687 11.1897 12.2963 11.1017 12.3853L10.1925 13.2648V13.453H14.6045V13.2648L13.7281 12.4068C13.6898 12.3783 13.6601 12.3399 13.642 12.2957C13.624 12.2516 13.6184 12.2033 13.6258 12.1562V5.84394C13.6183 5.79681 13.6239 5.74853 13.6419 5.70435C13.6599 5.66017 13.6897 5.62178 13.7281 5.59337H13.727Z" fill="#3A4BA4"/>
                        </svg>
                                             
                        Medium
                    </a>
                </li>
            </ul>
            <h3 class="company">Company</h3>
            <ul class="links">
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/products">Products</router-link></li>
                <li><router-link to="/our-solution">Solutions</router-link></li>
                <li><router-link to="/services">Services</router-link></li>
                <li><router-link to="/about">About</router-link></li>
                <li><router-link to="/contact">Contact Us</router-link></li>
            </ul>
            <h3 class="recentnews">Recent News</h3>
            <ul class="news">
                <li>
                    <h5>Crypto PR: The good, the...</h5>
                    <p>Published on: <span>June 10, 2021</span></p>
                </li>
                <li>
                    <h5>How to build an exemplary…</h5>
                    <p>Published on: <span>June 8, 2021</span></p>
                </li>
                <li>
                    <h5>Ethereum’s Ist Hard Fork...</h5>
                    <p>Published on: <span>June 7, 2021</span></p>
                </li>
            </ul>
            <h3 class="policies">Policies</h3>
            <ul class="policy">
                <li><router-link to="/privacy">Privacy Policy</router-link></li>
                <li><router-link to="/terms">Terms of use</router-link></li>
                <li><router-link to="/cookie">Cookie Policy</router-link></li>
                
            </ul>
            <p class="copyright">
                WallexLab (Wllx Lab EAD) is a financial technology and advisory company, not a financial institution. Financial services are provided by according licensed Institutions.
                Cryptocurrencies are highly speculative in nature, involve a high degree of risk and can rapidly and significantly decrease in value. It is reasonably possible for the value of cryptocurrencies to decrease to zero or near zero.
                Wallex Lab does not offer investments, legal, tax, accounting or other advice.
            </p>
        </div>

        <div class="container mobile">
            <center class="iPadHide"><img src="@/assets/logo_white.svg" alt=""></center>

            <div class="social mobile iPadHide">
                <center>
                    <a href="https://twitter.com/WallexLab" target="_blank">
                    <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-271 296.6 256.4 208.4" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M-14.6,321.2c-9.4,4.2-19.6,7-30.2,8.3c10.9-6.5,19.2-16.8,23.1-29.1c-10.2,6-21.4,10.4-33.4,12.8 c-9.6-10.2-23.3-16.6-38.4-16.6c-29,0-52.6,23.6-52.6,52.6c0,4.1,0.4,8.1,1.4,12c-43.7-2.2-82.5-23.1-108.4-55 c-4.5,7.8-7.1,16.8-7.1,26.5c0,18.2,9.3,34.3,23.4,43.8c-8.6-0.3-16.7-2.7-23.8-6.6v0.6c0,25.5,18.1,46.8,42.2,51.6 c-4.4,1.2-9.1,1.9-13.9,1.9c-3.4,0-6.7-0.3-9.9-0.9c6.7,20.9,26.1,36.1,49.1,36.5c-18,14.1-40.7,22.5-65.3,22.5 c-4.2,0-8.4-0.2-12.6-0.7c23.3,14.9,50.9,23.6,80.6,23.6c96.8,0,149.7-80.2,149.7-149.7c0-2.3,0-4.6-0.1-6.8 C-30.5,341-21.6,331.8-14.6,321.2"></path> </g></svg>                    
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100086423545168" target="_blank">
                        <svg height="20px" width="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_428_1625)">
                        <path d="M18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18C9.05273 18 9.10547 18 9.1582 17.9965V10.9934H7.22461V8.73984H9.1582V7.08047C9.1582 5.15742 10.3324 4.10977 12.048 4.10977C12.8707 4.10977 13.5773 4.16953 13.7812 4.19766V6.20859H12.6C11.6684 6.20859 11.4855 6.65156 11.4855 7.30195V8.73633H13.718L13.4262 10.9898H11.4855V17.652C15.2473 16.5727 18 13.1098 18 9Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_428_1625">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/wallexlab/" target="_blank">
                        <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M41.4,508.1H-8.5V348.4h49.9 V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7 C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6 c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z "></path> </g></svg>
                    </a>
                    <a href="https://www.instagram.com/wallexlab/" target="_blank">
                        <svg width="20px" height="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_428_1635)">
                            <path d="M10.7227 9C10.7227 9.95142 9.95142 10.7227 9 10.7227C8.04858 10.7227 7.27734 9.95142 7.27734 9C7.27734 8.04858 8.04858 7.27734 9 7.27734C9.95142 7.27734 10.7227 8.04858 10.7227 9Z" fill="white"/>
                            <path d="M13.0283 5.95204C12.9455 5.72765 12.8133 5.52454 12.6417 5.35782C12.475 5.18616 12.272 5.05405 12.0475 4.97124C11.8654 4.90051 11.5918 4.81633 11.0879 4.7934C10.5429 4.76854 10.3795 4.76318 8.99957 4.76318C7.61955 4.76318 7.45613 4.7684 6.91121 4.79326C6.40735 4.81633 6.13365 4.90051 5.95169 4.97124C5.72716 5.05405 5.52405 5.18616 5.35747 5.35782C5.18581 5.52454 5.0537 5.72751 4.97075 5.95204C4.90002 6.13414 4.81584 6.40784 4.79291 6.9117C4.76805 7.45662 4.7627 7.62004 4.7627 9.00006C4.7627 10.3799 4.76805 10.5434 4.79291 11.0884C4.81584 11.5923 4.90002 11.8658 4.97075 12.0479C5.0537 12.2725 5.18567 12.4754 5.35733 12.6422C5.52405 12.8138 5.72702 12.9459 5.95155 13.0287C6.13365 13.0996 6.40735 13.1838 6.91121 13.2067C7.45613 13.2316 7.61942 13.2368 8.99944 13.2368C10.3796 13.2368 10.543 13.2316 11.0878 13.2067C11.5917 13.1838 11.8654 13.0996 12.0475 13.0287C12.4982 12.8549 12.8544 12.4987 13.0283 12.0479C13.099 11.8658 13.1832 11.5923 13.2062 11.0884C13.2311 10.5434 13.2363 10.3799 13.2363 9.00006C13.2363 7.62004 13.2311 7.45662 13.2062 6.9117C13.1833 6.40784 13.0991 6.13414 13.0283 5.95204ZM8.99957 11.6537C7.53386 11.6537 6.34569 10.4656 6.34569 8.99992C6.34569 7.53421 7.53386 6.34618 8.99957 6.34618C10.4651 6.34618 11.6533 7.53421 11.6533 8.99992C11.6533 10.4656 10.4651 11.6537 8.99957 11.6537ZM11.7582 6.86144C11.4157 6.86144 11.1381 6.58376 11.1381 6.24126C11.1381 5.89876 11.4157 5.62108 11.7582 5.62108C12.1007 5.62108 12.3784 5.89876 12.3784 6.24126C12.3783 6.58376 12.1007 6.86144 11.7582 6.86144Z" fill="white"/>
                            <path d="M9 0C4.0302 0 0 4.0302 0 9C0 13.9698 4.0302 18 9 18C13.9698 18 18 13.9698 18 9C18 4.0302 13.9698 0 9 0ZM14.1368 11.1307C14.1118 11.6808 14.0243 12.0564 13.8966 12.3852C13.6281 13.0794 13.0794 13.6281 12.3852 13.8966C12.0565 14.0243 11.6808 14.1117 11.1308 14.1368C10.5797 14.1619 10.4036 14.168 9.00014 14.168C7.5965 14.168 7.42058 14.1619 6.86934 14.1368C6.31934 14.1117 5.9436 14.0243 5.61497 13.8966C5.27 13.7668 4.95772 13.5634 4.69954 13.3005C4.43669 13.0424 4.23331 12.73 4.10353 12.3852C3.97581 12.0565 3.88834 11.6808 3.86334 11.1308C3.83794 10.5796 3.83203 10.4035 3.83203 9C3.83203 7.5965 3.83794 7.42044 3.8632 6.86934C3.8882 6.3192 3.97554 5.9436 4.10326 5.61484C4.23303 5.27 4.43655 4.95758 4.69954 4.69954C4.95758 4.43655 5.27 4.23317 5.61484 4.10339C5.9436 3.97568 6.3192 3.88834 6.86934 3.8632C7.42044 3.83807 7.5965 3.83203 9 3.83203C10.4035 3.83203 10.5796 3.83807 11.1307 3.86334C11.6808 3.88834 12.0564 3.97568 12.3852 4.10326C12.73 4.23303 13.0424 4.43655 13.3006 4.69954C13.5634 4.95772 13.767 5.27 13.8966 5.61484C14.0245 5.9436 14.1118 6.3192 14.1369 6.86934C14.1621 7.42044 14.168 7.5965 14.168 9C14.168 10.4035 14.1621 10.5796 14.1368 11.1307Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_428_1635">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>                        
                    </a>
                    <a href="" target="_blank">
                        <svg width="20px" height="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="white"/>
                            <path d="M13.727 5.59337L14.625 4.7353V4.54712H11.5149L9.29864 10.0627L6.77761 4.54712H3.51716V4.7353L4.56545 5.99735C4.6155 6.04359 4.65411 6.10083 4.67825 6.16454C4.70239 6.22826 4.71139 6.29671 4.70455 6.36451V11.3247C4.7202 11.413 4.71463 11.5038 4.68832 11.5895C4.662 11.6752 4.61568 11.7534 4.55318 11.8177L3.375 13.2485V13.4346H6.72443V13.2464L5.54318 11.8146C5.48004 11.7505 5.43252 11.6726 5.40432 11.5871C5.37612 11.5016 5.36799 11.4108 5.38057 11.3217V7.03439L8.31989 13.4367H8.6625L11.1897 7.03439V12.1347C11.1897 12.2687 11.1897 12.2963 11.1017 12.3853L10.1925 13.2648V13.453H14.6045V13.2648L13.7281 12.4068C13.6898 12.3783 13.6601 12.3399 13.642 12.2957C13.624 12.2516 13.6184 12.2033 13.6258 12.1562V5.84394C13.6183 5.79681 13.6239 5.74853 13.6419 5.70435C13.6599 5.66017 13.6897 5.62178 13.7281 5.59337H13.727Z" fill="#3A4BA4"/>
                        </svg>                        
                    </a>
                </center>
            </div>

            <div class="fnav1">
                <h2>Company</h2>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/products">Products</router-link></li>
                <li><router-link to="/our-solution">Solutions</router-link></li>
                <li><router-link to="/services">Services</router-link></li>
                <li><router-link to="/about">About</router-link></li>
                <li><router-link to="/contact">Contact Us</router-link></li>
            </div>
            <div class="fnav1 policies">
                <h2>Policies</h2>
                <li><router-link to="/privacy">Privacy Policy</router-link></li>
                <li><router-link to="/terms">Terms Policy</router-link></li>
                <li><router-link to="/cookie">Cookie Policy</router-link></li>
                <li class="empty"><router-link to=""></router-link></li>
                <li class="empty"><router-link to=""></router-link></li>
                <li class="empty"><router-link to=""></router-link></li>
            </div>

            <ul class="fnav1 social">
                <img src="@/assets/logo_white.svg" alt="" />
                <li><a href="https://twitter.com/WallexLab" target="_blank">
                    <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-271 296.6 256.4 208.4" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M-14.6,321.2c-9.4,4.2-19.6,7-30.2,8.3c10.9-6.5,19.2-16.8,23.1-29.1c-10.2,6-21.4,10.4-33.4,12.8 c-9.6-10.2-23.3-16.6-38.4-16.6c-29,0-52.6,23.6-52.6,52.6c0,4.1,0.4,8.1,1.4,12c-43.7-2.2-82.5-23.1-108.4-55 c-4.5,7.8-7.1,16.8-7.1,26.5c0,18.2,9.3,34.3,23.4,43.8c-8.6-0.3-16.7-2.7-23.8-6.6v0.6c0,25.5,18.1,46.8,42.2,51.6 c-4.4,1.2-9.1,1.9-13.9,1.9c-3.4,0-6.7-0.3-9.9-0.9c6.7,20.9,26.1,36.1,49.1,36.5c-18,14.1-40.7,22.5-65.3,22.5 c-4.2,0-8.4-0.2-12.6-0.7c23.3,14.9,50.9,23.6,80.6,23.6c96.8,0,149.7-80.2,149.7-149.7c0-2.3,0-4.6-0.1-6.8 C-30.5,341-21.6,331.8-14.6,321.2"></path> </g></svg>                    
                    Twitter
                    </a>
                </li>
                <li><a href="https://www.facebook.com/profile.php?id=100086423545168" target="_blank">
                    <svg height="20px" width="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_428_1625)">
                        <path d="M18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18C9.05273 18 9.10547 18 9.1582 17.9965V10.9934H7.22461V8.73984H9.1582V7.08047C9.1582 5.15742 10.3324 4.10977 12.048 4.10977C12.8707 4.10977 13.5773 4.16953 13.7812 4.19766V6.20859H12.6C11.6684 6.20859 11.4855 6.65156 11.4855 7.30195V8.73633H13.718L13.4262 10.9898H11.4855V17.652C15.2473 16.5727 18 13.1098 18 9Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_428_1625">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        Facebook
                    </a>
                </li>
                <li><a href="https://www.linkedin.com/company/wallexlab/" target="_blank">
                    <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M41.4,508.1H-8.5V348.4h49.9 V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7 C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6 c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z "></path> </g></svg>
                    Linkedin
                    </a>
                </li>
                <li><a href="https://www.instagram.com/wallexlab/" target="_blank">
                    <svg width="20px" height="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_428_1635)">
                        <path d="M10.7227 9C10.7227 9.95142 9.95142 10.7227 9 10.7227C8.04858 10.7227 7.27734 9.95142 7.27734 9C7.27734 8.04858 8.04858 7.27734 9 7.27734C9.95142 7.27734 10.7227 8.04858 10.7227 9Z" fill="white"/>
                        <path d="M13.0283 5.95204C12.9455 5.72765 12.8133 5.52454 12.6417 5.35782C12.475 5.18616 12.272 5.05405 12.0475 4.97124C11.8654 4.90051 11.5918 4.81633 11.0879 4.7934C10.5429 4.76854 10.3795 4.76318 8.99957 4.76318C7.61955 4.76318 7.45613 4.7684 6.91121 4.79326C6.40735 4.81633 6.13365 4.90051 5.95169 4.97124C5.72716 5.05405 5.52405 5.18616 5.35747 5.35782C5.18581 5.52454 5.0537 5.72751 4.97075 5.95204C4.90002 6.13414 4.81584 6.40784 4.79291 6.9117C4.76805 7.45662 4.7627 7.62004 4.7627 9.00006C4.7627 10.3799 4.76805 10.5434 4.79291 11.0884C4.81584 11.5923 4.90002 11.8658 4.97075 12.0479C5.0537 12.2725 5.18567 12.4754 5.35733 12.6422C5.52405 12.8138 5.72702 12.9459 5.95155 13.0287C6.13365 13.0996 6.40735 13.1838 6.91121 13.2067C7.45613 13.2316 7.61942 13.2368 8.99944 13.2368C10.3796 13.2368 10.543 13.2316 11.0878 13.2067C11.5917 13.1838 11.8654 13.0996 12.0475 13.0287C12.4982 12.8549 12.8544 12.4987 13.0283 12.0479C13.099 11.8658 13.1832 11.5923 13.2062 11.0884C13.2311 10.5434 13.2363 10.3799 13.2363 9.00006C13.2363 7.62004 13.2311 7.45662 13.2062 6.9117C13.1833 6.40784 13.0991 6.13414 13.0283 5.95204ZM8.99957 11.6537C7.53386 11.6537 6.34569 10.4656 6.34569 8.99992C6.34569 7.53421 7.53386 6.34618 8.99957 6.34618C10.4651 6.34618 11.6533 7.53421 11.6533 8.99992C11.6533 10.4656 10.4651 11.6537 8.99957 11.6537ZM11.7582 6.86144C11.4157 6.86144 11.1381 6.58376 11.1381 6.24126C11.1381 5.89876 11.4157 5.62108 11.7582 5.62108C12.1007 5.62108 12.3784 5.89876 12.3784 6.24126C12.3783 6.58376 12.1007 6.86144 11.7582 6.86144Z" fill="white"/>
                        <path d="M9 0C4.0302 0 0 4.0302 0 9C0 13.9698 4.0302 18 9 18C13.9698 18 18 13.9698 18 9C18 4.0302 13.9698 0 9 0ZM14.1368 11.1307C14.1118 11.6808 14.0243 12.0564 13.8966 12.3852C13.6281 13.0794 13.0794 13.6281 12.3852 13.8966C12.0565 14.0243 11.6808 14.1117 11.1308 14.1368C10.5797 14.1619 10.4036 14.168 9.00014 14.168C7.5965 14.168 7.42058 14.1619 6.86934 14.1368C6.31934 14.1117 5.9436 14.0243 5.61497 13.8966C5.27 13.7668 4.95772 13.5634 4.69954 13.3005C4.43669 13.0424 4.23331 12.73 4.10353 12.3852C3.97581 12.0565 3.88834 11.6808 3.86334 11.1308C3.83794 10.5796 3.83203 10.4035 3.83203 9C3.83203 7.5965 3.83794 7.42044 3.8632 6.86934C3.8882 6.3192 3.97554 5.9436 4.10326 5.61484C4.23303 5.27 4.43655 4.95758 4.69954 4.69954C4.95758 4.43655 5.27 4.23317 5.61484 4.10339C5.9436 3.97568 6.3192 3.88834 6.86934 3.8632C7.42044 3.83807 7.5965 3.83203 9 3.83203C10.4035 3.83203 10.5796 3.83807 11.1307 3.86334C11.6808 3.88834 12.0564 3.97568 12.3852 4.10326C12.73 4.23303 13.0424 4.43655 13.3006 4.69954C13.5634 4.95772 13.767 5.27 13.8966 5.61484C14.0245 5.9436 14.1118 6.3192 14.1369 6.86934C14.1621 7.42044 14.168 7.5965 14.168 9C14.168 10.4035 14.1621 10.5796 14.1368 11.1307Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_428_1635">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>                        
                        Instagram
                    </a>
                </li>
                <li><a href="" target="_blank">
                    <svg width="20px" height="20px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="white"/>
                        <path d="M13.727 5.59337L14.625 4.7353V4.54712H11.5149L9.29864 10.0627L6.77761 4.54712H3.51716V4.7353L4.56545 5.99735C4.6155 6.04359 4.65411 6.10083 4.67825 6.16454C4.70239 6.22826 4.71139 6.29671 4.70455 6.36451V11.3247C4.7202 11.413 4.71463 11.5038 4.68832 11.5895C4.662 11.6752 4.61568 11.7534 4.55318 11.8177L3.375 13.2485V13.4346H6.72443V13.2464L5.54318 11.8146C5.48004 11.7505 5.43252 11.6726 5.40432 11.5871C5.37612 11.5016 5.36799 11.4108 5.38057 11.3217V7.03439L8.31989 13.4367H8.6625L11.1897 7.03439V12.1347C11.1897 12.2687 11.1897 12.2963 11.1017 12.3853L10.1925 13.2648V13.453H14.6045V13.2648L13.7281 12.4068C13.6898 12.3783 13.6601 12.3399 13.642 12.2957C13.624 12.2516 13.6184 12.2033 13.6258 12.1562V5.84394C13.6183 5.79681 13.6239 5.74853 13.6419 5.70435C13.6599 5.66017 13.6897 5.62178 13.7281 5.59337H13.727Z" fill="#3A4BA4"/>
                        </svg>
                                             
                        Medium
                    </a>
                </li>
                <li></li>
            </ul>

            <ul class="news">
                <h2>Recent News</h2><br/>
                <li>
                    <h5>Crypto PR: The good, the bad and the shoddy</h5>
                    <p>Published on: <span>June 10, 2021</span></p>
                </li>
                <li>
                    <h5>How to build an exemplary crypto exchange…</h5>
                    <p>Published on: <span>June 8, 2021</span></p>
                </li>
                <li>
                    <h5>Ethereum’s Ist Hard Fork, Important Updates…</h5>
                    <p>Published on: <span>June 7, 2021</span></p>
                </li>
            </ul>

            <p class="copyright">WallexLab (Wllx Lab EAD) is a financial technology and advisory company, not a financial institution. Financial services are provided by according licensed Institutions.
                Cryptocurrencies are highly speculative in nature, involve a high degree of risk and can rapidly and significantly decrease in value. It is reasonably possible for the value of cryptocurrencies to decrease to zero or near zero.
                Wallex Lab does not offer investments, legal, tax, accounting or other advice.</p>
            <center><a href="#"><img src="@/assets/gotop.svg" /></a></center>
        </div>
    </div>
</template>

<script script>

</script>

<style>
.footer {
    background: linear-gradient(286.23deg, #0394D9 -70.7%, #512E90 78.49%);
    padding-top: 5rem;
}

.footer .container {
    display: grid;
    grid-template-columns: 25% 25% 35%;
    grid-template-rows: 3rem auto auto;
    color: white;
    width: clamp(300px, 70%, 1024px);
}

.footer .container .policy {
    grid-row: 2/4;
    grid-column: 3/4;
    list-style: none;
}
.footer .policy li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}
.footer .policy a {
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
}

.footer .policy li::before {
    content: '';
    display: inline-block;
    height: 0.6rem;
    width: 0.6rem;
    background: url(@/assets/angle-arrow.svg);
      background-position-x: 0%;
      background-position-y: 0%;
      background-repeat: repeat;
      background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
  }

.footer .container h3 {
    grid-row: 1;
}

.footer .container .links {
    grid-row: 2/3;
    grid-column: 1/2;
    list-style: none;
}

.footer .links li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.footer .links li::before {
    content: '';
    display: inline-block;
    height: 0.6rem;
    width: 0.6rem;
    background: url(@/assets/angle-arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
}

.footer .links a {
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
}

.footer .news {
    grid-column: 2/3;
    grid-row: 2/3;
    list-style: none;
}

.footer .news li {
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer .news h5 {
    font-weight: 500;
    font-size: var(--font-size-200);
}

.footer .news p {
    font-weight: 400;
    font-size: var(--font-size-100);
    margin-top: 7px;
    color: rgba(255, 255, 255, 0.676);
}

.footer .news p span {
    margin-left: 10px;
}

.footer img {
    height: 2.5rem;
    margin: auto;
}

.footer .copyright {
    grid-column: 1/6;
    grid-row: 3;
    text-align: center;
    font-size: 12px;
    margin: 3rem 0;
}

@media screen and (max-width: 900px) {
    .footer .container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 8rem 3rem auto auto;
        color: white;
        width: clamp(300px, 70%, 1024px);
    }

    .footer .container img {
        grid-column: 1/4;
        grid-row: 1/2;
        margin-bottom: auto;
    }

    .footer .container h3 {
        grid-row: 2/3;
    }

    .footer .container .links,
    .footer .container .news {
        grid-row: 3/4;
    }

    .footer .copyright {
        grid-row: 4;
    }
}

@media screen and (max-width: 430px) {
    .footer .container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .footer h3 {
        margin-block: 3.5rem 2rem;
    }

    .footer .links {
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.footer .container .terms {
    grid-row: 1/3;
    grid-column: 4/4;
    list-style: none;
}
.footer .terms li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}
footer .terms a {
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
}
.footer .terms a {
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
}
.footer .container .terms img {
    overflow: visible;
    top: -15px;
    position: relative;
}
@media (min-width: 601px) {
    .policy {
        margin-left: 8.5vh;
    }
    .policies {
        margin-left: 8.5vh;
    }
    .desktopFlex {
        display: grid !important;
    }
}
@media (max-width: 600px) {
    .terms {
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .desktopFlex {
        display: none !important;
    }
}
.footer .terms svg {
    max-height: 20px;
    vertical-align: bottom;
    margin-right: 5px;
}

/* iPad Mini Horizontal */
@media screen and (max-width: 780px) and (max-height: 1050px) and (min-width: 750px) and (min-height: 1000px) {
    .footer .desktopFlex {
        display: none !important;
    }
    .footer .mobile {
        display: block !important
    }
    .footer .iPadHide {
        display: none !important;
    }
    .policies {
        margin-left: 0;
    }
    .fnav1 {
        width: 30% !important;
    }
}

/* iPad 12" */
@media screen and (max-width: 1025px) and (max-height: 1400px) and (min-width: 1000px) and (min-height: 1300px) {
    .policy {
        margin-left: 0;
    }
    .policies {
        margin-left: 0;
    }
    .footer .container {
        width: clamp(300px, 90%, 1024px);
    }
    .footer .container .policy {
        grid-row: 2/4;
    }
    .footer .iPadHide {
        display: none !important;
    }
    .fnav1 {
        width: 30% !important;
    }
}

/* iPad 11" */
@media screen and (max-width: 835px) and (max-height: 1200px) and (min-width: 800px) and (min-height: 1100px) {
    .fnav1.policies {
        margin-left: 2vh;
    }
    .fnav1.social {
        margin-left: 2vh;
    }
    .footer .container.mobile img {
        margin-bottom: 10px !important;
    }
    .footer .container.mobile img {
        margin-left: 15px;
    }
    .policy {
        margin-left: 0;
    }
    .policies {
        margin-left: 0;
    }
    .footer .container {
        width: clamp(300px, 90%, 1024px);
    }
    .footer .container .policy {
        grid-row: 3/4;
    }

    .footer .desktopFlex {
        display: none !important;
    }
    .footer .mobile {
        display: block !important
    }
    .footer .iPadHide {
        display: none !important;
    }

    .fnav1 {
        width: 30% !important; 
    }
}

/* iPad 13 */
@media screen and (max-width: 1000px) and (max-height: 1400px) and (min-width: 950px) and (min-height: 1300px) {
    .policy {
        margin-left: 0;
    }
    .policies {
        margin-left: 0;
    }
    .footer .container {
        width: clamp(300px, 90%, 1024px);
    }
    .footer .container .policy {
        grid-row: 2/4;
    }
    .footer .iPadHide {
        display: none !important;
    }
    .fnav1 {
        width: 30% !important;
    }
}
</style>