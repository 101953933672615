import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "solution" */ '../views/ServiceView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/product-wallet',
    name: 'ProductWallet',
    component: () => import('../views/ProductWallet')
  },
  {
    path: '/product-stablecoin',
    name: 'ProductStablecoin',
    component: () => import('../views/ProductStablecoin')
  },
  {
    path: '/product-exchange',
    name: 'ProductExchange',
    component: () => import('../views/ProductExchange')
  },
  {
    path: '/career',
    name: 'CareersView',
    component: () => import('../views/CareersView')
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: () => import('../views/ContactView')
  },
  {
    path: '/custody',
    name: 'CustodyView',
    component: () => import('../views/CustodyView')
  },
  {
    path: '/neo-banking',
    name: 'NeoBanking',
    component: () => import('../views/NeoBanking')
  },
  {
    path: '/crypto-processing',
    name: 'CryptoProcessing',
    component: () => import('../views/CryptoProcessing')
  },
  {
    path: '/our-solution',
    name: 'OurSolution',
    component: () => import('../views/OurSolution')
  },
  {
    path: '/use-cases',
    name: 'UseCases',
    component: () => import('../views/UseCases')
  },
  {
    path: '/news',
    name: 'NewsView',
    component: () => import('../views/NewsView')
  },
  {
    path: '/article',
    name: 'NewsSingle',
    component: () => import('../views/NewsSingle')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/PrivacyView')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/TermsView')
  },
  {
    path: '/cookie',
    name: 'Cookie',
    component: () => import('../views/CookieView')
  },
  {
    path: '/core-banking',
    name: 'CoreBanking',
    component: () => import('../views/CoreBanking')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });

    if (to.hash) {
      // BEFORE:
      // return { selector: to.hash }

      return { el: to.hash }
    }
  }
})

export default router
