<template>
    <div class="header">
        <nav class="container">
            <router-link to="/" @click="hideMenu"><img class="pc" src="@/assets/logo.svg" alt=""><img class="mobile" src="@/assets/logo-mobile.svg" alt=""></router-link>
            <ul ref="menu" class="links">
                <div class="dropdown link">
                    <li class="dropbtn" @click="openDropdown()">Products <svg width="10px" viewBox="0 0 16 16" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" id="svg2" version="1.1" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <metadata id="metadata7"> <rdf:rdf> <cc:work> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"></dc:type> <dc:title></dc:title> <dc:date>2021</dc:date> <dc:creator> <cc:agent> <dc:title>Timoth�e Giet</dc:title> </cc:agent> </dc:creator> <cc:license rdf:resource="http://creativecommons.org/licenses/by-sa/4.0/"></cc:license> </cc:work> <cc:license rdf:about="http://creativecommons.org/licenses/by-sa/4.0/"> <cc:permits rdf:resource="http://creativecommons.org/ns#Reproduction"></cc:permits> <cc:permits rdf:resource="http://creativecommons.org/ns#Distribution"></cc:permits> <cc:requires rdf:resource="http://creativecommons.org/ns#Notice"></cc:requires> <cc:requires rdf:resource="http://creativecommons.org/ns#Attribution"></cc:requires> <cc:permits rdf:resource="http://creativecommons.org/ns#DerivativeWorks"></cc:permits> <cc:requires rdf:resource="http://creativecommons.org/ns#ShareAlike"></cc:requires> </cc:license> </rdf:rdf> </metadata> <g id="layer1" transform="rotate(45 1254.793 524.438)"> <path style="fill:#373737;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="M.536 1044.409v-1.997h9v-9h2v11z" id="path4179"></path> </g> </g></svg></li>
                    <div id="myDropdown" ref="drop" class="dropdown-content">
                        <router-link @click="hideMenu" to="/product-wallet">Wallet</router-link>
                        <router-link @click="hideMenu" to="/product-stablecoin">Stablecoins</router-link>
                        <router-link @click="hideMenu" to="/custody">Custody</router-link>
                        <router-link @click="hideMenu" to="/core-banking">Core banking</router-link>
                        <router-link @click="hideMenu" to="/neo-banking">Neobanking</router-link>
                        <router-link @click="hideMenu" to="/crypto-processing">Crypto Processing</router-link>
                        <router-link @click="hideMenu" to="/product-exchange">Crypto Exchange</router-link>
                    </div>
                  </div>
                <router-link @click="hideMenu" to="/our-solution" class="link">
                    <li>Solutions</li>
                </router-link>
                <router-link @click="hideMenu" to="/services" class="link">
                    <li>Services</li>
                </router-link>
                <router-link @click="hideMenu" to="/use-cases" class="link">
                    <li>Use cases</li>
                </router-link>
                <router-link @click="hideMenu" to="/about" class="link">
                    <li>About</li>
                </router-link>
                <router-link @click="hideMenu" to="/career" class="link">
                    <li>Careers</li>
                </router-link>
                <router-link @click="hideMenu" to="/contact" class="contact">
                    <li>Contact Sales</li>
                </router-link>
            </ul>
            <img @click="toggleMenu" src="@/assets/hamburger-menu-more-2-svgrepo-com.svg" alt="" class="hamburger">
        </nav>
    </div>
</template>

<script>
export default {
  methods:{
    openDropdown() {
        document.getElementById("myDropdown").classList.toggle("show");
    },
  }
}
</script>

<script setup>
const { ref } = require("@vue/reactivity")

const menu = ref();
const drop = ref();

const toggleMenu = () => {
    menu.value.classList.toggle("show");
}

const hideMenu = () => {
    menu.value.classList.remove("show");
    drop.value.classList.remove("show");
}
</script>

<style scoped>
.header {
    height: 5rem;
    width: 100%;
    background: white;
    position: fixed;
    box-shadow: 5px 0 5px rgba(0, 0, 0, 0.048);
    z-index: 1000;
}

nav {
    display: flex;
    align-items: center;
    height: 100%;
    width: clamp(280px, 85%, 1040px);
}

.header img {
    height: 3rem;
}

.header .hamburger {
    display: none;
}

.header .links {
    display: flex;
    align-items: center;
    margin-left: auto;
    list-style: none;
    cursor: pointer;
}

.header .links li {
    margin-inline: 1rem;
    position: relative;
}

.header .links li::before {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 0;
    height: 3px;
    width: 0%;
    transition: 0.4s width ease-out;
    background: linear-gradient(90deg,#5433ff,#4526e1 .01%,#425cff 50%,#759cff);
}

.header .links .link {
    color: black;
    font-weight: 500;
    font-size: var(--font-size-200);
}

.header .links .link:hover li::before {
    width: 100%;
    opacity: 0.7;
}

.header .links .link.router-link-active.router-link-exact-active li::before {
    width: 100%;
}

.header .links .contact {
    padding: 0.5em 0em 0.5em 0px;
    background: linear-gradient(90deg, #5433FF 0%, #4526E1 0.01%, #425CFF 50%, #759CFF 100%);
    border-radius: 2rem;
    color: white;
    font-weight: 500;
}

@media screen and (max-width: 910px) {
    .header img {
        height: 2.25rem;
    }

    .header .links {
        position: absolute;
        top: 5rem;
        left: 0;
        height: 100vh;
        flex-direction: column;
        padding: 2rem 0;
        background: white;
        width: 100%;
        z-index: 100;
        clip-path: circle(0% at 100% 0);
        transition: 0.5s all ease-in;
        opacity: 0;
    }

    .header .links.show {
        clip-path: circle(150% at 100% 0);
        opacity: 1;
    }

    .header .links li {
        margin-bottom: 2.5rem;
    }

    .header .hamburger {
        display: block;
        margin-left: auto;
        margin-right: 10px;
        opacity: 0.7;
        height: 1.4rem;
        cursor: pointer;
    }

    .header .links .contact li {
        padding: 0 1em !important;
        margin: 0;
    }
}

/* Horizontal mobile */
@media screen and (max-height: 430px) and (max-width: 930px) {
    .header .links .contact {
        max-width: 120px;
        max-height: 50px;
    }
}


/* Dropdown */

  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 175px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: 10px;
    border-radius: 7px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}
</style>