import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '../node_modules/vue3-marquee-slider/dist/style.css'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import './assets/scss/main.scss'

createApp(App).use(router).mount('#app')
